<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">
      {{ t("headings.phase") }}
    </h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card p-4">
      <div
        class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3"
      >
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ phase.name }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.start_date") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ getLocaleDate(locale, phase.startDate) }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.end_date") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ getLocaleDate(locale, phase.endDate) }}</span>
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.project") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ project.name }}</span>
          </div>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <div class="flex justify-end">
        <form-button
          :command="
            () =>
              router.push(`/projects/${project.id}/phases/${phase.index}/edit`)
          "
          :disabled="waiting"
          id="project-edit-button"
          label="edit"
          :textVariant="company.primaryText"
          type="button"
          :variant="company.primary"
        />
      </div>
    </div>

    <div class="card">
      <h2 class="mb-2">{{ t("headings.tasks") }}</h2>
      <custom-table
        :fields="fields"
        :items="phaseTasks"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="startDate"
        :ascending="false"
        :sortFields="sortFields"
        @row-click="
          (id) =>
            router.push(
              `/projects/${project.id}/phases/${phase.name}/tasks/${id}`
            )
        "
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="
                (project, phase) =>
                  router.push(
                    `/projects/${project.id}/phases/${phase.name}/tasks/create`
                  )
              "
              id="header-create-task-button"
              label="create_task"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(startDate)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(endDate)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(status)="{ value }">
          {{ t("status." + value) }}
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
import { getUserName } from "../utils/userUtils";

export default {
  components: { Breadcrumb, CustomTable, FormButton, Skeleton },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const company = computed(() => store.state.company.company);
    const project = computed(() => store.state.project.project);
    const phases = project.value.phases;
    /*const phase = computed(() => {
      return phases.value.find((phase) => phase.id == roleId);
      });
      */
    const phase = ref({
      name: "Valmistelut",
      startDate: "2023-05-01",
      endDate: "2023-05-05",
    });

    const phaseTasks = ref([
      {
        name: "Kohteen tutkiminen",
        startDate: "2023-05-01",
        endDate: "2023-05-02",
        status: "todo",
      },
      {
        name: "Tarvikkeiden pakkaaminen",
        startDate: "2023-05-03",
        endDate: "2023-05-05",
        status: "todo",
      },
    ]);

    const waiting = computed(() => {
      if (store.state.project.waiting) return true;
      return false;
    });

    const fields = ref([
      {
        key: "name",
        label: "name",
      },
      {
        key: "startDate",
        label: "start_date",
      },
      {
        key: "endDate",
        label: "end_date",
      },
      {
        key: "status",
        label: "status",
      },
    ]);
    const searchFields = ref(["name", "startDate", "endDate", "status"]);
    const sortFields = ref(["name", "startDate", "endDate", "status"]);

    onMounted(() => {
      if (!project.value.id || project.value.id != route.params.projectId) {
        store.dispatch("project/getProject", route.params.projectId);
      }
    });

    watch(project.value, () => {
      store.dispatch("project/getProject", route.params.projectId);
    });

    return {
      company,
      fields,
      getLocaleDate,
      getUserName,
      locale,
      phase,
      phaseTasks,
      project,
      route,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
